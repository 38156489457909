var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "placeholder": "Search...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  }), _vm.$permissionAbility(_vm.ATTENDANCE_POLICY_MAP_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Create ")])] : _vm._e()], 2)]), _c('vue-good-table', {
    attrs: {
      "styleClass": "vgt-table table-custom-style condensed striped",
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row$remoteAtte, _props$row$remoteAtte2, _props$row2, _props$row2$remoteAtt, _props$row2$remoteAtt2, _props$row3, _props$row3$division, _props$row3$division$, _props$row4, _props$row4$division, _props$row4$division$;
        return [props.column.field === 'name' ? [(_props$row = props.row) !== null && _props$row !== void 0 && (_props$row$remoteAtte = _props$row.remoteAttendancePolicy) !== null && _props$row$remoteAtte !== void 0 && (_props$row$remoteAtte2 = _props$row$remoteAtte.data) !== null && _props$row$remoteAtte2 !== void 0 && _props$row$remoteAtte2.name ? [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s((_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$remoteAtt = _props$row2.remoteAttendancePolicy) === null || _props$row2$remoteAtt === void 0 ? void 0 : (_props$row2$remoteAtt2 = _props$row2$remoteAtt.data) === null || _props$row2$remoteAtt2 === void 0 ? void 0 : _props$row2$remoteAtt2.name))])] : _vm._e()] : _vm._e(), props.column.field === 'division_name' ? [(_props$row3 = props.row) !== null && _props$row3 !== void 0 && (_props$row3$division = _props$row3.division) !== null && _props$row3$division !== void 0 && (_props$row3$division$ = _props$row3$division.data) !== null && _props$row3$division$ !== void 0 && _props$row3$division$.name ? [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s((_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : (_props$row4$division = _props$row4.division) === null || _props$row4$division === void 0 ? void 0 : (_props$row4$division$ = _props$row4$division.data) === null || _props$row4$division$ === void 0 ? void 0 : _props$row4$division$.name))])] : _vm._e()] : _vm._e(), props.column.field === 'action' ? _c('span', [_vm.$permissionAbility(_vm.ATTENDANCE_POLICY_MAP_EDIT, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "Edit2Icon",
            "title": "Edit",
            "size": "16"
          }
        })], 1)] : _vm._e(), _vm.$permissionAbility(_vm.ATTENDANCE_POLICY_MAP_DELETE, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "TrashIcon",
            "title": "Delete",
            "size": "16"
          }
        })], 1)] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-attendance-policy-remote-map-form",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit' : 'Create',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "remoteAttendancePolicyMapValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Policy*",
      "label-for": "remote-attendance-policy-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "policy",
      "vid": "remote_attendance_policy_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          attrs: {
            "id": "remote-attendance-policy-id",
            "placeholder": "Select Policy",
            "options": _vm.remoteAttendancePoliciesOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectRemoteAttendancePoliciesId,
            callback: function callback($$v) {
              _vm.selectRemoteAttendancePoliciesId = $$v;
            },
            expression: "selectRemoteAttendancePoliciesId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Division*",
      "label-for": "division-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "division",
      "vid": "division_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "id": "division-id",
            "placeholder": "Select Division",
            "options": _vm.divisionsOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name"
          },
          on: {
            "input": _vm.onChangeDivision
          },
          model: {
            value: _vm.selectDivisionId,
            callback: function callback($$v) {
              _vm.selectDivisionId = $$v;
            },
            expression: "selectDivisionId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _c('small', {
          staticClass: "text-primary"
        }, [_vm._v(_vm._s(_vm.policyMapDivisionTooltip))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Department",
      "label-for": "department-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "department",
      "vid": "department_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "department-id",
            "placeholder": "Select Department",
            "options": _vm.departmentsOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "disabled": _vm.departmentsOption.length > 0 ? false : true,
            "multiple": ""
          },
          on: {
            "input": _vm.onChangeDepartment
          },
          model: {
            value: _vm.selectDepartmentsIds,
            callback: function callback($$v) {
              _vm.selectDepartmentsIds = $$v;
            },
            expression: "selectDepartmentsIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _c('small', {
          staticClass: "text-primary"
        }, [_vm._v(_vm._s(_vm.policyMapDepartmentTooltip))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Employee",
      "label-for": "user-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "Employees",
      "vid": "user_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user-id",
            "placeholder": "Select Employee",
            "options": _vm.usersOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "disabled": _vm.usersOption.length > 0 ? false : true,
            "multiple": "",
            "clearable": ""
          },
          on: {
            "input": _vm.onChangeUsersTooltip
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectUsersIds,
            callback: function callback($$v) {
              _vm.selectUsersIds = $$v;
            },
            expression: "selectUsersIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _c('small', {
          staticClass: "text-primary"
        }, [_vm._v(_vm._s(_vm.policyMapUserTooltip))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Effective Date*",
      "label-for": "effective_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "effective date",
      "vid": "effective_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-datepicker', {
          attrs: {
            "id": "effective_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "reset-button": ""
          },
          on: {
            "context": _vm.onContextStartDate
          },
          model: {
            value: _vm.effectiveDate,
            callback: function callback($$v) {
              _vm.effectiveDate = $$v;
            },
            expression: "effectiveDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isDesignationFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }