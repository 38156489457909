<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          />
          <template
            v-if="$permissionAbility(ATTENDANCE_POLICY_MAP_CREATE, permissions)"
          >
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        styleClass="vgt-table table-custom-style condensed striped"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'id', type: 'desc' }],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- policy_name -->
          <template v-if="props.column.field === 'name'">
            <template v-if="props.row?.remoteAttendancePolicy?.data?.name">
              <span class="text-nowrap">{{
                props.row?.remoteAttendancePolicy?.data?.name
              }}</span>
            </template>
          </template>

          <!-- division_name -->
          <template v-if="props.column.field === 'division_name'">
            <template v-if="props.row?.division?.data?.name">
              <span class="text-nowrap">{{
                props.row?.division?.data?.name
              }}</span>
            </template>
          </template>
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">

            <template v-if="$permissionAbility(ATTENDANCE_POLICY_MAP_EDIT, permissions)">
                <span @click="onShow(props.row)">
                  <feather-icon
                      v-b-tooltip.hover
                      icon="Edit2Icon"
                      class="mr-50 custom-icon cursor-pointer"
                      title="Edit"
                      size="16"
                  />
                </span>
              </template>

            <template
                  v-if="$permissionAbility(ATTENDANCE_POLICY_MAP_DELETE, permissions)"
              >
                <span @click="onDelete(props.row)">
                  <feather-icon
                      v-b-tooltip.hover
                      icon="TrashIcon"
                      class="mr-50 custom-icon cursor-pointer"
                      title="Delete"
                      size="16"
                  />
                </span>
              </template>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-attendance-policy-remote-map-form"
      centered
      :title="modelType == 'editModel' ? 'Edit' : 'Create'"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="remoteAttendancePolicyMapValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- select Remote Attendance Policies -->

          <b-form-group label="Policy*" label-for="remote-attendance-policy-id">
            <ValidationProvider
              name="policy"
              v-slot="{ errors }"
              vid="remote_attendance_policy_id"
              rules="required"
            >
              <v-select
                id="remote-attendance-policy-id"
                placeholder="Select Policy"
                v-model="selectRemoteAttendancePoliciesId"
                :options="remoteAttendancePoliciesOption"
                :reduce="(option) => option.id"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- select division-->
          <b-form-group label="Division*" label-for="division-id">
            <ValidationProvider
              name="division"
              v-slot="{ errors }"
              vid="division_id"
              rules="required"
            >
              <v-select
                id="division-id"
                placeholder="Select Division"
                v-model="selectDivisionId"
                :options="divisionsOption"
                :reduce="(option) => option.id"
                label="name"
                @input="onChangeDivision"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <small class="text-primary">{{ policyMapDivisionTooltip }}</small>

            </ValidationProvider>
          </b-form-group>

          <!-- select department-->
          <b-form-group label="Department" label-for="department-id">
            <ValidationProvider
              name="department"
              v-slot="{ errors }"
              vid="department_id"
            >
              <v-select
                id="department-id"
                placeholder="Select Department"
                v-model="selectDepartmentsIds"
                :options="departmentsOption"
                :reduce="(option) => option.id"
                label="name"
                :disabled="departmentsOption.length > 0 ? false : true"
                multiple
                @input="onChangeDepartment"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <small class="text-primary">{{ policyMapDepartmentTooltip }}</small>

            </ValidationProvider>
          </b-form-group>

          <!-- select users-->
          <b-form-group label="Employee" label-for="user-id">
            <ValidationProvider
              name="Employees"
              v-slot="{ errors }"
              vid="user_id"
            >
              <v-select
                id="user-id"
                placeholder="Select Employee"
                v-model="selectUsersIds"
                :options="usersOption"
                :reduce="(option) => option.id"
                label="name"
                :disabled="usersOption.length > 0 ? false : true"
                multiple
                clearable
                @input="onChangeUsersTooltip"
              >
                <template #option="data">
                  <UserSelect :user="data" />
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>

              <small class="text-primary">{{ policyMapUserTooltip }}</small>

            </ValidationProvider>
          </b-form-group>

          <!-- select Effective Date-->
          <b-form-group label="Effective Date*" label-for="effective_date">
            <ValidationProvider
              name="effective date"
              v-slot="{ errors }"
              vid="effective_date"
              rules="required"
            >
              <b-form-datepicker
                id="effective_date"
                v-model="effectiveDate"
                :state="errors.length > 0 ? false : null"
                locale="en-US"
                reset-button
                @context="onContextStartDate"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isDesignationFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormTimepicker,
  BFormDatepicker, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max, integer } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  ATTENDANCE_POLICY_MAP_CREATE,
  ATTENDANCE_POLICY_MAP_EDIT,
  ATTENDANCE_POLICY_MAP_DELETE,
} from "@/helpers/permissionsConstant";

import { _lodash } from "@/helpers/lodash";
import UserSelect from '@/layouts/components/UserSelect.vue'

export default {
  name: "RemoteAttendancePolicyMayView",
  components: {
    UserSelect,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormTimepicker,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      ATTENDANCE_POLICY_MAP_CREATE,
      ATTENDANCE_POLICY_MAP_EDIT,
      ATTENDANCE_POLICY_MAP_DELETE,
      modelType: "",
      remoteAttendancePolicyMapId: "",
      effectiveDate: "",
      selectRemoteAttendancePoliciesId: "",
      remoteAttendancePoliciesOption: [],
      selectDivisionId: "",

      policyMapDivisionTooltip: "",
      policyMapDepartmentTooltip: "",
      policyMapUserTooltip: "",

      divisionsOption: [],
      selectDepartmentsIds: [],
      departmentsOption: [],
      selectUsersIds: [],
      usersOption: [],
      pageLength: 10,
      columns: [
        {
          label: "Policy Name",
          field: "name",
          sortable: false,
        },
        {
          label: "Effective Date",
          field: "effective_date",
          formatFn: this.formatDate,
        },
        {
          label: "Division",
          field: "division_name",
          sortable: false,
        },

        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isDesignationFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      const [remoteAttendancePolicies, divisions] = await Promise.all([
        this.getRemoteAttendancePolicies(),
        this.getDivisions(),
      ]);

      //  remote Attendance Policies
      this.remoteAttendancePoliciesOption = (
        remoteAttendancePolicies?.data?.data || []
      ).map((item) => {
        return {
          name: item.name,
          id: item.id,
        };
      });

      //  divisions
      this.divisionsOption = (divisions?.data?.data || []).map((item) => {
        return {
          name: item.name,
          id: item.id,
        };
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    formatFnTableFormateDate(value) {
      if (value) {
        return this.$moment(value).format("ll");
      }
    },
    async onChangeDivision(id) {

      this.divisionTooltip(id);

      this.selectDepartmentsIds = [];
      this.departmentsOption = [];
      this.selectUsersIds = [];
      this.usersOption = [];

      try {
        const res = await this.getDivisionDepartmentsById(id);

        //  departmentsOption
        this.departmentsOption = (res?.data?.data?.departments?.data || []).map(
          (item) => {
            return {
              name: item.name,
              id: item.id,
            };
          }
        );
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async onChangeDepartment(department_ids) {

      this.departmentTooltip(department_ids);

      this.selectUsersIds = [];
      this.usersOption = [];

      if(Object.keys(department_ids).length > 0)
      {
        try {
          const res = await this.getDivisionDepartmentsUsersById(
              this.selectDivisionId,
              department_ids
          );

          //  usersOption
          let userArray = [];

          (res?.data?.data?.departments || []).map((item) => {
            let departmentName = item?.name;
            (item?.users || []).map((item) => {
              userArray.push({
                avatar: item?.avatar,
                name: item?.name,
                email: item?.email,
                mobile: item?.mobile,
                employee_number: item?.employee_number,
                id: item?.id,
                departmentId: item?.department_id,
              });
            });
          });

          this.usersOption = userArray;
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: error?.response?.data?.message,
            },
          });
        }
      }
    },

    divisionTooltip(id){
        if (id) {
          this.setPolicyMapDivisionTooltip();
        } else {
          this.resetPolicyMapDivisionTooltip();
        }
    },

    departmentTooltip(department_ids){
      if(department_ids.length > 0){
        this.resetPolicyMapDivisionTooltip();
        this.setPolicyMapDepartmentTooltip();
      }else{
        this.setPolicyMapDivisionTooltip();
        this.resetPolicyMapDepartmentTooltip();
      }
    },

    onChangeUsersTooltip(selectUsersIds){
      if(selectUsersIds.length > 0){
        this.resetPolicyMapDepartmentTooltip();
        this.setPolicyMapUserTooltip();
      }else{
        this.setPolicyMapDepartmentTooltip();
        this.resetPolicyMapUserTooltip();
      }
    },

    setPolicyMapDivisionTooltip(){
      this.policyMapDivisionTooltip = "All Employees of The Above Division are Selected";
    },
    resetPolicyMapDivisionTooltip(){
      this.policyMapDivisionTooltip = "";
    },

    setPolicyMapDepartmentTooltip(){
        this.policyMapDepartmentTooltip = "All Employees of The Above Departments/Department” are Selected";
    },
    resetPolicyMapDepartmentTooltip(){
      this.policyMapDepartmentTooltip = "";
    },

    setPolicyMapUserTooltip(){
      this.policyMapUserTooltip = "Only Selected Employees Will be Selected";
    },

    resetPolicyMapUserTooltip(){
      this.policyMapUserTooltip = "";
    },

    formatFnTableCheckIn(value) {
      if (value) {
        return this.$moment(value, "HH:mm:ss").format("LTS");
      }
    },
    formatFnTableCheckOut(value) {
      if (value) {
        return this.$moment(value, "HH:mm:ss").format("LTS");
      }
    },
    onContextStartDate(ctx) {
      this.effectiveDate = ctx.selectedYMD;
    },
    showModal() {
      this.$bvModal.show("modal-attendance-policy-remote-map-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-attendance-policy-remote-map-form");
      this.resetModal();
    },
    resetModal() {
      this.modelType = "";
      this.remoteAttendancePolicyMapId = "";
      this.selectDivisionId = "";
      this.selectRemoteAttendancePoliciesId = "";
      this.effectiveDate = "";
      this.selectDepartmentsIds = [];
      this.departmentsOption = [];
      this.selectUsersIds = [];
      this.usersOption = [];

      this.resetPolicyMapDivisionTooltip();
      this.resetPolicyMapDepartmentTooltip();
      this.resetPolicyMapUserTooltip();

    },
    async onShow(value) {
      this.modelType = "editModel";

      this.remoteAttendancePolicyMapId = value?.id;
      this.selectRemoteAttendancePoliciesId =
        value?.remote_attendance_policy_id;
      this.effectiveDate = value?.effective_date;
      this.selectDivisionId = value?.division_id;

      let selectDepartmentsIds = (value?.departments || []).map(
        (item) => item?.id
      );
      this.selectDepartmentsIds = selectDepartmentsIds;

      this.selectUsersIds = (value?.users || []).map((item) => item?.id);

      try {
        const getDivisionDepartmentsById =
          await this.getDivisionDepartmentsById(value?.division_id);
        //  departmentsOption
        this.departmentsOption = (
          getDivisionDepartmentsById?.data?.data?.departments?.data || []
        ).map((item) => {
          return {
            name: item.name,
            id: item.id,
          };
        });

        if (!_lodash.isEmpty(selectDepartmentsIds)) {
          const getDivisionDepartmentsUsersById =
            await this.getDivisionDepartmentsUsersById(
              value?.division_id,
              selectDepartmentsIds
            );

          //  usersOption
          let userArray = [];

          (getDivisionDepartmentsUsersById?.data?.data?.departments || []).map(
            (item) => {
              let departmentName = item?.name;
              (item?.users || []).map((item) => {
                userArray.push({
                  name: item?.name,
                  email: item?.email,
                  mobile: item?.mobile,
                  employee_number: item?.employee_number,
                  id: item?.id,
                  avatar: item?.avatar,
                  departmentId: item?.department_id,
                });
              });
            }
          );

          this.usersOption = userArray;
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }

      this.showModal();
    },

    async onDelete(row) {
      const { id } = row
      this.$swal({
        title: "Warning!",
        text: `Are You Sure, You Want To Delete ${row.remoteAttendancePolicy?.data?.name}?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/attendances/policy/remote/map/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async getRemoteAttendancePolicyMap(params) {
      return await this.$api.get(
        "api/attendances/policy/remote/map?include=division,remoteAttendancePolicy",
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
          },
        }
      );
    },
    async getDivisions() {
      return await this.$api.get("api/divisions/all");
    },
    async getRemoteAttendancePolicies() {
      return await this.$api.get("api/attendances/policy/remote/all");
    },
    async getDivisionDepartmentsById(id) {
      return await this.$api.get(
        `api/divisions/${id}/departments?include=departments`
      );
    },
    async getDivisionDepartmentsUsersById(id, department_ids) {
      return await this.$api.get(`api/divisions/${id}/departments/users`, {
        params: {
          department_ids: department_ids,
        },
      });
    },

    async loadItems() {
      try {
        const [remoteAttendancePolicyMap] = await Promise.all([
          this.getRemoteAttendancePolicyMap({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ]);

        const data = remoteAttendancePolicyMap?.data?.data;
        const meta = remoteAttendancePolicyMap?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.remoteAttendancePolicyMapValidation
        .validate()
        .then(async (success) => {
          if (success) {
            try {
              if (this.modelType == "editModel") {
                this.isDesignationFormSubmitLoading = true;
                await this.$api.put(
                  `/api/attendances/policy/remote/map/${this.remoteAttendancePolicyMapId}`,
                  {
                    division_id: this.selectDivisionId,
                    remote_attendance_policy_id:
                      this.selectRemoteAttendancePoliciesId,
                    effective_date: this.effectiveDate,
                    department_id: this.selectDepartmentsIds,
                    user_id: this.selectUsersIds,
                  }
                );
                this.isDesignationFormSubmitLoading = false;
                this.loadItems();
                this.hiddenModal();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Successfully Updated",
                  },
                });
              } else {
                this.isDesignationFormSubmitLoading = true;
                await this.$api.post(
                  "/api/attendances/policy/remote/map",

                  {
                    division_id: this.selectDivisionId,
                    remote_attendance_policy_id:
                      this.selectRemoteAttendancePoliciesId,
                    effective_date: this.effectiveDate,
                    department_id: this.selectDepartmentsIds,
                    user_id: this.selectUsersIds,
                  }
                );
                this.isDesignationFormSubmitLoading = false;
                this.hiddenModal();

                this.loadItems();

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Successfully Created",
                  },
                });
              }
            } catch (error) {
              this.isDesignationFormSubmitLoading = false;
              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }

              if (error?.response?.data?.errors) {
                this.$refs.remoteAttendancePolicyMapValidation.setErrors(
                  error?.response?.data?.errors
                );
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.actionBtn span{
  padding: 8px;
  margin: 1px;
  cursor: pointer;
}
</style>
